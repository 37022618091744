import { QueryState } from '@redux/reducers/queryParams/types';

import { ALLOWED_QUERY_PARAMS } from '@shared/router/constants';

import { QueryParamsActionType } from './enum';

export const setQueryParams = (payload: Record<string, string>) => ({
  type: QueryParamsActionType.Set,
  payload,
});

export const checkQueryParams = (query: QueryState) => dispatch => {
  for (const param in query) {
    if (query.hasOwnProperty(param) && ALLOWED_QUERY_PARAMS.includes(param)) {
      dispatch(
        setQueryParams({
          [param]: query[param],
        })
      );
    }
  }
};
