import { CountriesActionType } from '@redux/reducers/countries/enum';
import { CountriesState } from '@redux/reducers/countries/types';

import Api from '@api/index';
import { _sendError } from '@shared/logger/functions';

export const loadCountries = () => async dispatch => {
  try {
    let currencies;
    let countries;

    await Promise.all([Api.getCountries(), Api.getCurrencies()]).then(
      ([countriesArray, currenciesArray]) => {
        currencies = currenciesArray;
        countries = countriesArray;
      }
    );

    const countriesWithCurrency: CountriesState[] = [];

    currencies.forEach(currency => {
      countries.forEach(country => {
        if (country.currency === currency.id) {
          countriesWithCurrency.push({
            ...country,
            currency,
          });
        }
      });
    });

    dispatch(setCountries(countriesWithCurrency));
  } catch (error) {
    _sendError(
      'The API method getCountries() or getCurrencies() was failed.',
      error
    );

    return Promise.reject({ has500: true });
  }
};

const setCountries = (countries: CountriesState[]) => ({
  type: CountriesActionType.Set,
  payload: countries,
});
