import { QueryState } from '@redux/reducers/queryParams/types';

import redirect from '@utils/redirect';

import affiliates from '@public/affiliates.json';

export const redirectToAffiliateLink = (query: QueryState, res) => {
  const { promo, clickId } = query;

  if (promo && !clickId) {
    new Promise(resolve => {
      setTimeout(() => {
        const affiliate = affiliates.find(
          affiliate => affiliate.code === promo
        );

        if (affiliate) {
          resolve(affiliate.link);
        }
      }, 0);
    }).then((link: string) => {
      redirect(res, link, 301);
    });
  }
};
